<template>
  <div class="pageview">
    <van-nav-bar title="网上办事" fixed left-arrow @click-left="onCanback" />
    <div class="listview">
      <van-cell-group>
        <van-cell
          center
          :title="item.Name"
          is-link
          :key="index"
          v-for="(item, index) in dataList"
          :to="'/work/apply/' + item.NWMId + '/' + item.WorkKind "
        >
          <template #title>
            <div class="woktitle">
              <van-tag
                class="tag"
                :type="item.WorkKind == 1 ? 'primary' : 'success'"
                >{{ item.WorkKindDesc }}</van-tag
              >
              <span class="custom-title">{{ item.Name }}</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { WeGetNWMatterList } from "@/api/Network";

export default {
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getNWMatterList(this.$route.params);
  },
  methods: {
    getNWMatterList(data) {
      WeGetNWMatterList(data).then((res) => {
        this.dataList = res.data.data;
      });
    },
    // 后退按钮
    onCanback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.pageview {
  background: #f7f8fa;
  min-height: 100%;
  padding-top:45px;
}
.listview {
  padding: 20px 10px;
}
.woktitle .custom-title {
  width: 76%;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}
.woktitle .tag {
  display: inline-block;
  vertical-align: middle;
}
</style>